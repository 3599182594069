package digital.steva.dot.app.rest

import digital.steva.dot.app.Login
import digital.steva.dot.app.rest.RestHelper.client
import io.ktor.client.call.body
import io.ktor.client.request.get

object DocumentTypeResource {
    suspend fun getAll(login: Login): List<DocumentType> {
         return client(login).get("${login.serverUrl}/api/dms/document_types")
            .body()
    }

    suspend fun get(login: Login, id: String): DocumentType {
        return client(login).get("${login.serverUrl}/api/dms/document_types/${id}")
            .body()
    }
}
