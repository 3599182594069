package digital.steva.dot.app.rest

import androidx.compose.ui.text.intl.Locale
import kotlinx.collections.immutable.PersistentMap
import kotlinx.collections.immutable.persistentMapOf
import kotlinx.serialization.SerialName
import kotlinx.serialization.Serializable
import kotlinx.serialization.Transient

typealias I18nString = Map<String, String?>
fun I18nString.getCurrentLocale() = this.get(Locale.current.language)

@Serializable
data class DocumentType(
    val id: String,
    val name: String? = null,
    val displayName: String? = null,
    val displayNameI18n: I18nString = emptyMap(),
    val description: String? = null,
    val descriptionI18n: I18nString = emptyMap(),
    val createdAt: String? = null,
    val updatedAt: String? = null,
    val dataSchema: String? = null,
    val uiSchema: String? = null,
    val icon: String? = null,
)

fun List<DocumentType>.findById(id: String?) = find { it.id == id }

@Serializable
data class Document(
    val id: String,
    val documentTypeId: String? = null,
    val number: String? = null,
    val done: Boolean = false,
    @SerialName("formData")
    val dataString: String? = null,
    val createdAt: String? = null,
    val updatedAt: String? = null,
    @Transient
    val data: PersistentMap<String, Any> = persistentMapOf(),
    @Transient
    val isNew: Boolean = false,
    @Transient
    val isModified: Boolean = false,
    @Transient
    val isDownloaded: Boolean = false
)

fun List<Document>.findById(id: String?) = find { it.id == id }
