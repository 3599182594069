package digital.steva.dot.app.middlewares

import digital.steva.dot.app.AppState
import digital.steva.dot.app.ShowToast
import digital.steva.dot.app.dispatch
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.reduxkotlin.middleware

@Suppress("UNUSED_ANONYMOUS_PARAMETER")
fun createMainMiddlware() =
    middleware<AppState> { store, next, action ->
        when (action) {
            is ShowToast -> {
                if (action.message != null) {
                    CoroutineScope(Dispatchers.Default).launch {
                        delay(2000)
                        dispatch(ShowToast(null))
                    }
                }
                next(action)
            }

            else -> next(action)
        }
    }
