package digital.steva.dot.app

import androidx.compose.ui.ExperimentalComposeUiApi
import androidx.compose.ui.window.CanvasBasedWindow
import kotlinx.browser.window
import kotlinx.serialization.json.Json
import org.jetbrains.skiko.wasm.onWasmReady
import org.w3c.dom.MessageEvent

@OptIn(ExperimentalComposeUiApi::class)
fun main() {
    onWasmReady {
        window.addEventListener("message", { event ->
            val messageEvent = event as MessageEvent
            try {
                val message =
                    Json.decodeFromString<Map<String, String>>(messageEvent.data.toString())
                when (message["action"]) {
                    "SetZoomLevel" -> {
                        dispatch(SetZoomLevel(message["zoomLevel"]?.toFloatOrNull() ?: 1.0f))
                    }
                }
            } catch (e: Throwable) {
            }
        })

        CanvasBasedWindow("dot.") {
            AppView()
        }
    }
}
