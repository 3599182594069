package digital.steva.formumat.ui

import androidx.compose.runtime.Composable
import androidx.compose.ui.graphics.toComposeImageBitmap
import com.darkrockstudios.libraries.mpfilepicker.FilePicker
import digital.steva.formumat.redux.Dispatcher
import digital.steva.formumat.redux.FormumatValues
import digital.steva.formumat.redux.SetValue
import io.ktor.util.encodeBase64
import org.jetbrains.skia.Image

actual fun convertByteArrayToImageBitmap(imageBytes: ByteArray) =
    Image.makeFromEncoded(imageBytes).toComposeImageBitmap()

@Composable
actual fun ImageFilePicker(
    show: Boolean,
    onClosePicker: () -> Unit,
    property: String,
    values: FormumatValues,
    dispatch: Dispatcher
) {
//    FilePicker(
//        show = show,
//        fileExtensions = listOf("jpg", "jpeg", "png", "webp")
//    ) { file ->
//        if (file != null) {
//            val bytes = ByteArray(0)
//            dispatch(SetValue(property, bytes.encodeBase64(), values.listContext))
//        }
//        onClosePicker()
//    }
}
