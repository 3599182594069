package digital.steva.dot.app.views

import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.padding
import androidx.compose.runtime.Composable
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import digital.steva.formumat.redux.Dispatcher
import digital.steva.formumat.redux.FormumatState
import digital.steva.formumat.ui.FormView

@Composable
fun PageFormumat(
    formumatState: FormumatState,
    dispatch: Dispatcher,
    paddingValues: PaddingValues,
) {
    Column(
        modifier = Modifier
            .fillMaxSize()
            .padding(paddingValues = paddingValues),
        verticalArrangement = Arrangement.Top
    ) {
        Box(modifier = Modifier.padding(8.dp)) {
            FormView(formumatState.uiSchema, formumatState.dataSchema, formumatState, dispatch)
        }
    }
}
