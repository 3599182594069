// Qasim Nawaz
// https://medium.com/@qasimnawaz_70901/kotlin-multiplatform-compose-unified-image-capture-and-gallery-picker-with-permission-handling-8a8f8cc9cc82
// https://github.com/QasimNawaz/KMPImagePicker

package digital.steva.formumat.helpers

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import androidx.compose.ui.graphics.ImageBitmap

actual class SharedImage {
    actual fun toByteArray(): ByteArray? = null
    actual fun toImageBitmap(): ImageBitmap? = null
}

@Composable
actual fun rememberCameraManager(onResult: (SharedImage?) -> Unit): CameraManager = CameraManager({})

actual class CameraManager actual constructor(
    private val onLaunch: () -> Unit
) {
    actual fun launch() {
        onLaunch()
    }
}

@Composable
actual fun rememberGalleryManager(onResult: (SharedImage?) -> Unit): GalleryManager {
    return remember {
        GalleryManager(onLaunch = {})
    }
}
actual class GalleryManager actual constructor(private val onLaunch: () -> Unit) {
    actual fun launch() {
        onLaunch()
    }
}
