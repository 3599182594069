package digital.steva.dot.app.rest

import digital.steva.dot.app.Login
import digital.steva.dot.app.rest.RestHelper.client
import digital.steva.formumat.redux.parseValues
import digital.steva.formumat.redux.stringifyValues
import io.ktor.client.call.body
import io.ktor.client.request.get
import io.ktor.client.request.post
import io.ktor.client.request.put
import io.ktor.client.request.setBody
import io.ktor.http.ContentType
import io.ktor.http.contentType

object DocumentResource {
    suspend fun getAll(login: Login): List<Document> {
        return client(login).get("${login.serverUrl}/api/dms/documents")
            .body()
    }

    suspend fun get(login: Login, id: String): Document {
        val document: Document = client(login).get("${login.serverUrl}/api/dms/documents/${id}")
            .body()
        return document.copy(data = parseValues(document.dataString ?: "{}"))
    }

    suspend fun create(login: Login, document: Document) {
        client(login).post("${login.serverUrl}/api/dms/documents") {
            contentType(ContentType.Application.Json)
            setBody(document.copy(dataString = stringifyValues(document.data)))
        }
    }

    suspend fun update(login: Login, document: Document) {
        client(login).put("${login.serverUrl}/api/dms/documents/${document.id}") {
            contentType(ContentType.Application.Json)
            setBody(document.copy(dataString = stringifyValues(document.data)))
        }
    }
}
