package digital.steva.dot.app.middlewares

import digital.steva.dot.app.AppState
import digital.steva.dot.app.HideProgressSpinner
import digital.steva.dot.app.LoadFormumatDataSchema
import digital.steva.dot.app.LoadFormumatUiSchema
import digital.steva.dot.app.MR
import digital.steva.dot.app.Page
import digital.steva.dot.app.RestGetDocumentType
import digital.steva.dot.app.RestGetDocument
import digital.steva.dot.app.RestGetDocuments
import digital.steva.dot.app.RestGetDocumentTypes
import digital.steva.dot.app.RestLogin
import digital.steva.dot.app.RestLogout
import digital.steva.dot.app.SetDocuments
import digital.steva.dot.app.SetDocumentTypes
import digital.steva.dot.app.ShowPage
import digital.steva.dot.app.ShowProgressSpinner
import digital.steva.dot.app.ShowToast
import digital.steva.dot.app.Synchronize
import digital.steva.dot.app.UpdateDocument
import digital.steva.dot.app.dispatch
import digital.steva.dot.app.rest.DocumentTypeResource
import digital.steva.dot.app.rest.DocumentResource
import digital.steva.dot.app.rest.SessionResource
import digital.steva.formumat.redux.SetValues
import io.ktor.http.HttpStatusCode
import kotlinx.collections.immutable.toPersistentList
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.launch
import org.reduxkotlin.middleware

@Suppress("UNUSED_ANONYMOUS_PARAMETER")
fun createRestMiddlware() =
    middleware<AppState> { store, next, action ->
        val login = store.state.login

        when (action) {
            is RestLogin -> CoroutineScope(Dispatchers.Default).launch {
                try {
                    dispatch(ShowProgressSpinner())
                    when (SessionResource.login(login).status) {
                        HttpStatusCode.OK -> {
                            dispatch(ShowPage(Page.PAGE_DOCUMENTS))
                            dispatch(Synchronize())
                        }

                        HttpStatusCode.Unauthorized -> {
                            dispatch(ShowToast(MR.strings.error_login_wrong_username_or_password))
                        }

                        else -> {
                            dispatch(ShowToast(MR.strings.error_login))
                        }
                    }
                } catch (e: Throwable) {
                    println(e.message)
                    dispatch(ShowToast(MR.strings.error_login))
                }
                dispatch(HideProgressSpinner())
            }

            is RestLogout -> CoroutineScope(Dispatchers.Default).launch {
                dispatch(ShowProgressSpinner())
                dispatch(ShowPage(Page.PAGE_LOGIN))
                dispatch(HideProgressSpinner())
            }

            is RestGetDocumentTypes -> CoroutineScope(Dispatchers.Default).launch {
                dispatch(ShowProgressSpinner())
                dispatch(SetDocumentTypes(DocumentTypeResource.getAll(login).toPersistentList()))
                dispatch(HideProgressSpinner())
            }

            is RestGetDocumentType -> CoroutineScope(Dispatchers.Default).launch {
                dispatch(ShowProgressSpinner())
                val documentType = DocumentTypeResource.get(login, action.id)
                dispatch(LoadFormumatDataSchema(documentType.dataSchema!!))
                dispatch(LoadFormumatUiSchema(documentType.uiSchema!!))
                dispatch(HideProgressSpinner())
            }

            is RestGetDocuments -> CoroutineScope(Dispatchers.Default).launch {
                dispatch(ShowProgressSpinner())
                dispatch(SetDocuments(DocumentResource.getAll(login).toPersistentList()))
                dispatch(HideProgressSpinner())
            }

            is RestGetDocument -> CoroutineScope(Dispatchers.Default).launch {
                dispatch(ShowProgressSpinner())
                val document = DocumentResource.get(login, action.id).copy(isDownloaded = true)
                dispatch(UpdateDocument(document))
                if (store.state.currentDocument?.id == document.id) {
                    dispatch(SetValues(document.data))
                }
                dispatch(HideProgressSpinner())
            }

            is Synchronize -> CoroutineScope(Dispatchers.Default).launch {
                dispatch(ShowProgressSpinner())
                store.state.document.forEach { document ->
                    when {
                        document.isNew -> DocumentResource.create(login, document)
                        document.isModified -> DocumentResource.update(login, document)
                    }
                }
                dispatch(RestGetDocuments())
                dispatch(RestGetDocumentTypes())
                dispatch(HideProgressSpinner())
            }

            else -> next(action)
        }
    }
