package digital.steva.formumat.ui

import Base64.decodeFromBase64
import androidx.compose.foundation.BorderStroke
import androidx.compose.foundation.Image
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.Spacer
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.shape.CircleShape
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Clear
import androidx.compose.material.icons.filled.Edit
import androidx.compose.material3.ButtonDefaults
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.OutlinedButton
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.layout.ContentScale
import androidx.compose.ui.unit.dp
import digital.steva.formumat.redux.Dispatcher
import digital.steva.formumat.redux.FormumatValues
import digital.steva.formumat.redux.SetValue
import digital.steva.formumat.schema.SignatureField
import digital.steva.formumat.schema.StringEncoding
import digital.steva.formumat.schema.StringType
import io.ktor.util.encodeBase64

@Suppress("UNUSED_PARAMETER")
@Composable
fun SignatureView(
    signatureField: SignatureField,
    type: StringType?,
    values: FormumatValues,
    dispatch: Dispatcher,
    enabled: Boolean = true,
    modifier: Modifier = Modifier
) {
    val value = values[signatureField.property?.eval(values)]
    val label = signatureField.title.eval(values)
    var showDialog by remember { mutableStateOf(false) }

    Column(
        modifier = modifier
    ) {
        if (!label.isNullOrBlank()) {
            Text(
                text = label,
                modifier = Modifier.padding(vertical = 8.dp)
            )
        }

        val imageBytes = if (!value?.toString().isNullOrEmpty()) {
            when (type?.contentEncoding) {
                StringEncoding.BASE_64 -> {
                    value.toString().decodeFromBase64()
                }

                else -> null
            }
        } else {
            null
        }

        Row(
            verticalAlignment = Alignment.Top
        ) {
            Column {
                if (imageBytes == null) {
                    OutlinedButton(
                        onClick = { showDialog = true },
                        shape = CircleShape,
                        border = BorderStroke(2.dp, MaterialTheme.colorScheme.primary),
                        contentPadding = PaddingValues(0.dp),
                        colors = ButtonDefaults.outlinedButtonColors(contentColor = MaterialTheme.colorScheme.primary),
                        modifier = Modifier.size(48.dp)
                    ) {
                        Icon(Icons.Filled.Edit, "")
                    }
                } else {
                    if (signatureField.clearable.eval(values)) {
                        OutlinedButton(
                            onClick = { dispatch(SetValue(signatureField.property?.eval(values) ?: "", null, values.listContext)) },
                            shape = CircleShape,
                            border = BorderStroke(2.dp, MaterialTheme.colorScheme.primary),
                            contentPadding = PaddingValues(0.dp),
                            colors = ButtonDefaults.outlinedButtonColors(contentColor = MaterialTheme.colorScheme.primary),
                            modifier = Modifier.size(48.dp)
                        ) {
                            Icon(Icons.Filled.Clear, "")
                        }
                    }
                }
            }
            if (imageBytes != null) {
                Spacer(modifier = Modifier.size(8.dp))
                Image(
                    bitmap = convertByteArrayToImageBitmap(imageBytes),
                    contentDescription = "",
                    contentScale = ContentScale.FillWidth
                )
            }
        }
    }

    if (showDialog) {
        SignatureDialog(
            onClose = { showDialog = false },
            onSave = {
                dispatch(SetValue(signatureField.property?.eval(values) ?: "", it?.encodeBase64(), values.listContext))
                showDialog = false
            },
            onClear = {
                dispatch(SetValue(signatureField.property?.eval(values) ?: "", null, values.listContext))
                showDialog = false
            }
        )
    }
}

@Composable
expect fun SignatureDialog(
    onClose: () -> Unit,
    onSave: (ByteArray?) -> Unit,
    onClear: () -> Unit
)
