package digital.steva.dot.app.views

import androidx.compose.foundation.ExperimentalFoundationApi
import androidx.compose.foundation.clickable
import androidx.compose.foundation.combinedClickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.PaddingValues
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.size
import androidx.compose.foundation.lazy.LazyColumn
import androidx.compose.foundation.lazy.items
import androidx.compose.foundation.pager.HorizontalPager
import androidx.compose.foundation.pager.rememberPagerState
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.filled.Check
import androidx.compose.material.icons.filled.Create
import androidx.compose.material.icons.filled.Edit
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.MaterialTheme
import androidx.compose.material3.Text
import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.snapshotFlow
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.draw.drawBehind
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.graphics.drawscope.Stroke
import androidx.compose.ui.graphics.vector.ImageVector
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.style.TextOverflow
import androidx.compose.ui.unit.Dp
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import dev.icerock.moko.resources.compose.stringResource
import digital.steva.dot.app.AddDocument
import digital.steva.dot.app.ClearFormumat
import digital.steva.dot.app.MR
import digital.steva.dot.app.Page
import digital.steva.dot.app.RestGetDocument
import digital.steva.dot.app.RestGetDocumentType
import digital.steva.dot.app.SetCurrentDocument
import digital.steva.dot.app.ShowPage
import digital.steva.dot.app.isPresent
import digital.steva.dot.app.rest.Document
import digital.steva.dot.app.rest.DocumentType
import digital.steva.dot.app.rest.findById
import digital.steva.dot.app.rest.getCurrentLocale
import digital.steva.formumat.redux.Dispatcher
import digital.steva.formumat.redux.FormumatState
import digital.steva.formumat.redux.FormumatValues
import digital.steva.formumat.redux.SetCurrentPage
import digital.steva.formumat.redux.SetValues
import digital.steva.formumat.ui.IconByName
import kotlinx.datetime.Clock
import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import kotlinx.uuid.UUID

@OptIn(ExperimentalFoundationApi::class)
@Composable
fun PageDocuments(
    documents: List<Document>,
    documentTypes: List<DocumentType>,
    dispatch: Dispatcher,
    paddingValues: PaddingValues,
) {
    LazyColumn(
        modifier = Modifier.padding(paddingValues = paddingValues)
    ) {
        items(documents.sortedBy { it.number }) { document ->
            val documentType = documentTypes.findById(document.documentTypeId)
            Row(
                modifier = Modifier
                    .fillMaxWidth()
                    .combinedClickable(
                        onClick = {
                            document.documentTypeId?.let { documentTypeId ->
                                if (!document.isNew && !document.isDownloaded) {
                                    dispatch(RestGetDocument(document.id))
                                }
                                dispatch(SetCurrentDocument(document))
                                dispatch(RestGetDocumentType(documentTypeId))
                                dispatch(SetValues(document.data))
                                dispatch(ShowPage(Page.PAGE_DOCUMENT))
                            }
                        },
                        onLongClick = {
                        }
                    ),
                verticalAlignment = Alignment.CenterVertically,
            ) {
                Box(
                    modifier = Modifier.padding(start = 20.dp)
                ) {
                    BadgedDocumentIcon(document, documentType)
                }
                Column(
                    modifier = Modifier
                        .weight(1.0f)
                        .padding(start = 12.dp, top = 24.dp, bottom = 24.dp),
                ) {
                    Text(
                        text = document.number ?: "",
                        fontSize = 22.sp,
                        fontWeight = FontWeight.W700,
                        modifier = Modifier.padding(bottom = 6.dp)
                    )
                    Text(
                        text = documentType?.displayNameI18n?.getCurrentLocale() ?: documentType?.displayName ?: "",
                        fontSize = 20.sp,
                        fontWeight = FontWeight.W500,
                        modifier = Modifier.padding(bottom = 6.dp)
                    )
                    Row(
                        horizontalArrangement = Arrangement.spacedBy(8.dp)
                    ) {
                        if (document.isNew) {
                            Text(
                                text = stringResource(MR.strings.document_state_new),
                                fontSize = 12.sp,
                                fontWeight = FontWeight.W500
                            )
                        }
                        if (document.isModified) {
                            Text(
                                text = stringResource(MR.strings.document_state_modified),
                                fontSize = 12.sp,
                                fontWeight = FontWeight.W500
                            )
                        }
                        if (document.done) {
                            Text(
                                text = stringResource(MR.strings.document_state_done),
                                fontSize = 12.sp,
                                fontWeight = FontWeight.W500
                            )
                        }
                        if (!document.isNew && !document.isModified && !document.done) {
                            Text(
                                text = stringResource(MR.strings.document_state_current),
                                fontSize = 12.sp,
                                fontWeight = FontWeight.W500
                            )
                        }
                    }
                }
            }
            HorizontalDivider(
                color = MaterialTheme.colorScheme.inverseOnSurface,
                thickness = 2.dp
            )
        }
    }
}

@Composable
fun BadgedDocumentIcon(document: Document, documentType: DocumentType?) {
    Box(
        contentAlignment = Alignment.TopEnd
    ) {
        IconByName(
            name = if (documentType?.icon.isPresent()) documentType?.icon!! else "Task",
            modifier = Modifier.size(64.dp),
            color = MaterialTheme.colorScheme.secondary
        )
        when {
            document.isNew ->
                BadgeIcon(Icons.Filled.Create, color = Color(0xFFCCCC33))

            document.isModified ->
                BadgeIcon(Icons.Filled.Edit, color = Color(0xFFCC4444))

            document.done ->
                BadgeIcon(Icons.Filled.Check, color = Color(0xFF55AA55))

            else -> {
            }
        }
    }
}

@Composable
fun BadgeIcon(imageVector: ImageVector, color: Color, size: Dp = 18.dp) {
    Box(contentAlignment = Alignment.Center) {
        Icon(
            imageVector = imageVector,
            contentDescription = "",
            tint = Color.White,
            modifier = Modifier
                .size(size)
                .padding(2.dp)
                .drawBehind {
                    drawCircle(
                        color = Color.White,
                        radius = size.value,
                        style = Stroke(width = 2.dp.toPx())
                    )
                    drawCircle(
                        color = color,
                        radius = size.value
                    )
                }
        )
    }
}

@Composable
fun PageNewDocument(
    documentTypes: List<DocumentType>,
    dispatch: Dispatcher,
    paddingValues: PaddingValues,
) {
    LazyColumn(
        modifier = Modifier.padding(paddingValues = paddingValues)
    ) {
        items(documentTypes) { documentType ->
            Row(
                verticalAlignment = Alignment.CenterVertically,
                modifier = Modifier
                    .fillMaxWidth()
                    .clickable {
                        val now = Clock.System.now().toLocalDateTime(TimeZone.UTC)
                        val document = Document(
                            id = UUID().toString(),
                            documentTypeId = documentType.id,
                            number = (now.year * 10000000000 + now.monthNumber * 100000000 + now.dayOfMonth * 1000000 + now.hour * 10000 + now.minute * 100 + now.second).toString(),
                            isNew = true
                        )
                        dispatch(AddDocument(document))
                        dispatch(SetCurrentDocument(document))
                        dispatch(ClearFormumat())
                        dispatch(RestGetDocumentType(documentType.id))
                        dispatch(ShowPage(Page.PAGE_FORMUMAT))
                    }
            ) {
                Box(
                    modifier = Modifier.padding(start = 20.dp)
                ) {
                    IconByName(
                        name = if (documentType?.icon.isNullOrBlank()) "Task" else documentType.icon!!,
                        modifier = Modifier.size(64.dp),
                        color = MaterialTheme.colorScheme.secondary
                    )
                }
                Column(
                    modifier = Modifier
                        .weight(1.0f)
                        .padding(start = 12.dp, end = 12.dp ,top = 29.dp, bottom = 29.dp),
                ) {
                    Text(
                        text = documentType.displayNameI18n.getCurrentLocale() ?: documentType.displayName ?: "",
                        fontSize = 22.sp,
                        fontWeight = FontWeight.W500,
                        modifier = Modifier.padding(bottom = 10.dp)
                    )
                    Text(
                        text = documentType.descriptionI18n.getCurrentLocale() ?: documentType.description ?: "",
                        style = MaterialTheme.typography.headlineSmall,
                        modifier = Modifier.padding(bottom = 10.dp),
                        maxLines = 2,
                        overflow = TextOverflow.Ellipsis
                    )
                }
            }
            HorizontalDivider(
                color = MaterialTheme.colorScheme.inverseOnSurface,
                thickness = 2.dp
            )
        }
    }
}

@OptIn(ExperimentalFoundationApi::class)
@Suppress("UNUSED_PARAMETER")
@Composable
fun PageDocument(
    document: Document?,
    formumatState: FormumatState,
    dispatch: Dispatcher,
    paddingValues: PaddingValues,
) {
    val values = FormumatValues(formumatState.data, formumatState.dataSchema.typesByKey, formumatState.uiSchema.fieldsByKey, dispatch)
    val pages = formumatState.uiSchema.allVisiblePages(values)
    val pagerState = rememberPagerState(pageCount = { pages.size })

    LaunchedEffect(formumatState.currentPage) {
        pagerState.scrollToPage(pages.indexOf(formumatState.currentPage))
    }

    LaunchedEffect(pagerState, pages) {
        snapshotFlow { pagerState.currentPage }.collect { page ->
            if (page < pages.size) {
                dispatch(SetCurrentPage(pages[page]))
            }
        }
    }

    HorizontalPager(
        state = pagerState
    ) { page ->
        Column(
            verticalArrangement = Arrangement.Top,
            modifier = Modifier.fillMaxSize().padding(paddingValues = paddingValues),
        ) {
            Box {
                val selectedPage = pages[page]
                digital.steva.formumat.ui.PageView(
                    selectedPage,
                    selectedPage?.items ?: formumatState.uiSchema.items,
                    formumatState.dataSchema.typesByKey,
                    values,
                    showTitle = false,
                    dispatch
                )
            }
        }
    }
}
