package digital.steva.dot.app.rest

import androidx.compose.ui.text.intl.Locale
import digital.steva.dot.app.Login
import io.ktor.client.HttpClient
import io.ktor.client.plugins.auth.Auth
import io.ktor.client.plugins.auth.providers.BasicAuthCredentials
import io.ktor.client.plugins.auth.providers.basic
import io.ktor.client.plugins.contentnegotiation.ContentNegotiation
import io.ktor.client.plugins.defaultRequest
import io.ktor.client.request.header
import io.ktor.http.HttpHeaders
import io.ktor.serialization.kotlinx.json.json
import kotlinx.serialization.json.Json

object RestHelper {
    fun client(login: Login) = HttpClient() {
        install(ContentNegotiation) {
            json(Json {
                ignoreUnknownKeys = true
                coerceInputValues = true
            })
        }
        install(Auth) {
            basic {
                credentials {
                    BasicAuthCredentials(username = login.username, password = login.password)
                }
                sendWithoutRequest { _ -> true }
            }
        }
        defaultRequest {
            header(HttpHeaders.AcceptLanguage, Locale.current.language)
        }
    }
}
