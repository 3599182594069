package digital.steva.formumat.redux

import com.github.murzagalin.evaluator.Function
import digital.steva.formumat.helpers.Convert

val If = object : Function("if", 3) {
    override fun invoke(values: Map<String, Any?>, vararg args: Any?): Any? {
        val condition = Convert.toBoolean(args[0])
        return if (condition) args[1] else args[2]
    }
}

val Not = object : Function("not", 1) {
    override fun invoke(values: Map<String, Any?>, vararg args: Any?): Any? {
        require(args.size == 1) { "$name should be called with exactly one argument" }
        return !Convert.toBoolean(args[0])
    }
}

val Or = object : Function("or", 1..Int.MAX_VALUE) {
    override fun invoke(values: Map<String, Any?>, vararg args: Any?): Any? {
        require(args.size > 1) { "$name should be called with at least 2 arguments" }
        return args.any { Convert.toBoolean(it) }
    }
}

val And = object : Function("and", 1..Int.MAX_VALUE) {
    override fun invoke(values: Map<String, Any?>, vararg args: Any?): Any? {
        require(args.size > 1) { "$name should be called with at least 2 arguments" }
        return args.all { Convert.toBoolean(it) }
    }
}
