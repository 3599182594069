package digital.steva.dot.app.theme

import androidx.compose.foundation.isSystemInDarkTheme
import androidx.compose.material3.MaterialTheme
import androidx.compose.runtime.Composable

@Composable
fun AppTheme(
    useDarkTheme: Boolean = isSystemInDarkTheme(),
    content: @Composable () -> Unit,
) {
    val colorScheme = when {
        useDarkTheme -> DarkColors
        else -> LightColors
    }

    val font = createFont()
    if (font != null) {
        MaterialTheme(
            colorScheme = colorScheme,
            typography = createTypo(font),
            content = content
        )
    }
}
