package digital.steva.dot.app.views

import androidx.compose.foundation.background
import androidx.compose.foundation.clickable
import androidx.compose.foundation.layout.Arrangement
import androidx.compose.foundation.layout.Box
import androidx.compose.foundation.layout.BoxWithConstraints
import androidx.compose.foundation.layout.Column
import androidx.compose.foundation.layout.Row
import androidx.compose.foundation.layout.fillMaxHeight
import androidx.compose.foundation.layout.fillMaxSize
import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.foundation.layout.padding
import androidx.compose.foundation.layout.width
import androidx.compose.foundation.layout.widthIn
import androidx.compose.foundation.shape.RoundedCornerShape
import androidx.compose.foundation.text.KeyboardActions
import androidx.compose.foundation.text.KeyboardOptions
import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.automirrored.filled.Login
import androidx.compose.material.icons.filled.Computer
import androidx.compose.material.icons.filled.Key
import androidx.compose.material.icons.filled.Person
import androidx.compose.material.icons.filled.Visibility
import androidx.compose.material.icons.filled.VisibilityOff
import androidx.compose.material3.Button
import androidx.compose.material3.HorizontalDivider
import androidx.compose.material3.Icon
import androidx.compose.material3.Text
import androidx.compose.material3.TextField
import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import androidx.compose.runtime.setValue
import androidx.compose.ui.Alignment
import androidx.compose.ui.Modifier
import androidx.compose.ui.focus.FocusDirection
import androidx.compose.ui.graphics.Brush
import androidx.compose.ui.graphics.Color
import androidx.compose.ui.platform.LocalFocusManager
import androidx.compose.ui.text.font.FontWeight
import androidx.compose.ui.text.input.ImeAction
import androidx.compose.ui.text.input.KeyboardCapitalization
import androidx.compose.ui.text.input.KeyboardType
import androidx.compose.ui.text.input.PasswordVisualTransformation
import androidx.compose.ui.text.input.VisualTransformation
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.sp
import dev.icerock.moko.resources.compose.stringResource
import digital.steva.dot.app.AppState
import digital.steva.dot.app.BuildConfig
import digital.steva.dot.app.MR
import digital.steva.dot.app.RestLogin
import digital.steva.dot.app.SetLogin
import digital.steva.dot.app.theme.textStyleAboutPageHeader
import digital.steva.formumat.redux.Dispatcher

@Composable
fun PageLogin(
    state: AppState,
    dispatch: Dispatcher,
) {
    BoxWithConstraints() {
        Box(
            contentAlignment = Alignment.Center,
            modifier = Modifier.fillMaxSize()
        ) {
            LoginBackground()
            LoginHeader()
            LoginBody(state, dispatch)
        }
        ErrorDialog(state.error, dispatch)
    }
}

@Composable
fun LoginBackground(
) {
    Box(
        modifier = Modifier
            .fillMaxSize()
            .background(
                Brush.horizontalGradient(
                    colorStops = arrayOf(
                        0.0f to Color(0xff404797),
                        1.0f to Color(0xff21b095)
                    )
                )
            )
    )
}

@Composable
fun LoginHeader() {
    Box(
        contentAlignment = Alignment.TopCenter,
        modifier = Modifier.fillMaxSize().padding(top = 20.dp)
    ) {
        Text(
            text = "dot.",
            style = textStyleAboutPageHeader()
        )
    }
}

@Composable
fun LoginBody(
    state: AppState,
    dispatch: Dispatcher,
    modifier: Modifier = Modifier,
) {
    Box(
        modifier = modifier
            .fillMaxSize()
    ) {
        Column(
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center,
            modifier = Modifier.fillMaxSize()
        ) {
            LoginForm(state, dispatch)
        }
        Copyright(
            modifier = Modifier
                .align(Alignment.BottomStart)
                .padding(start = 24.dp, bottom = 24.dp)
        )
    }
}

@Composable
fun Copyright(
    modifier: Modifier = Modifier,
) {
    Text(
        text = "©2023-2024 steva GmbH - v${BuildConfig.PROJECT_VERSION}",
        color = Color.White,
        modifier = modifier
    )
}

@Composable
fun LoginForm(
    state: AppState,
    dispatch: Dispatcher,
) {
    var showPassword by remember { mutableStateOf(false) }
    val focusManager = LocalFocusManager.current

    Column(
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.spacedBy(12.dp)
    ) {
        TextField(
            value = state.login.serverUrl,
            placeholder = { Text(text = stringResource(MR.strings.page_login_server_url)) },
            keyboardOptions = KeyboardOptions(
                capitalization = KeyboardCapitalization.None,
                autoCorrect = false,
                keyboardType = KeyboardType.Text,
                imeAction = ImeAction.None
            ),
            keyboardActions = KeyboardActions(onNext = {
                focusManager.moveFocus(FocusDirection.Down)
            }),
            shape = RoundedCornerShape(8.dp),
            leadingIcon = {
                Row(
                    horizontalArrangement = Arrangement.SpaceBetween,
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier.padding(start = 8.dp)
                ) {
                    Icon(Icons.Filled.Computer, stringResource(MR.strings.icon_web))
                    HorizontalDivider(
                        modifier = Modifier
                            .height(20.dp)
                            .width(20.dp)
                            .padding(start = 18.dp)
                    )
                }
            },
            singleLine = true,
            onValueChange = {
                if (it.contains("\n")) {
                    focusManager.moveFocus(FocusDirection.Down)
                }
                if (it.contains("\t")) {
                    focusManager.moveFocus(FocusDirection.Down)
                }
                dispatch(
                    SetLogin(
                        state.login.copy(
                            serverUrl = it.replace("\n", "").replace("\t", "")
                        )
                    )
                )
            },
            modifier = Modifier
                .height(55.dp)
                .widthIn(max = 280.dp)
        )
        TextField(
            value = state.login.username,
            placeholder = { Text(text = stringResource(MR.strings.page_login_username)) },
            keyboardOptions = KeyboardOptions(
                capitalization = KeyboardCapitalization.None,
                autoCorrect = false,
                keyboardType = KeyboardType.Text,
                imeAction = ImeAction.None
            ),
            keyboardActions = KeyboardActions(onNext = {
                focusManager.moveFocus(FocusDirection.Down)
            }),
            shape = RoundedCornerShape(8.dp),
            leadingIcon = {
                Row(
                    horizontalArrangement = Arrangement.SpaceBetween,
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier.padding(start = 8.dp)
                ) {
                    Icon(Icons.Filled.Person, stringResource(MR.strings.icon_person))
                    HorizontalDivider(
                        modifier = Modifier
                            .height(20.dp)
                            .width(20.dp)
                            .padding(start = 18.dp)
                    )
                }
            },
            singleLine = true,
            onValueChange = {
                if (it.contains("\n")) {
                    focusManager.moveFocus(FocusDirection.Down)
                }
                if (it.contains("\t")) {
                    focusManager.moveFocus(FocusDirection.Down)
                }
                dispatch(
                    SetLogin(
                        state.login.copy(
                            username = it.replace("\n", "").replace("\t", "")
                        )
                    )
                )
            },
            modifier = Modifier
                .height(55.dp)
                .widthIn(max = 280.dp)
        )
        TextField(
            value = state.login.password,
            placeholder = { Text(text = stringResource(MR.strings.page_login_password)) },
            keyboardOptions = KeyboardOptions(
                capitalization = KeyboardCapitalization.None,
                autoCorrect = false,
                keyboardType = KeyboardType.Password,
                imeAction = ImeAction.None
            ),
            keyboardActions = KeyboardActions(onNext = {
                focusManager.moveFocus(FocusDirection.Down)
            }),
            shape = RoundedCornerShape(8.dp),
            visualTransformation = if (showPassword) VisualTransformation.None else PasswordVisualTransformation(),
            leadingIcon = {
                Row(
                    horizontalArrangement = Arrangement.SpaceBetween,
                    verticalAlignment = Alignment.CenterVertically,
                    modifier = Modifier.padding(start = 8.dp)
                ) {
                    Icon(Icons.Filled.Key, stringResource(MR.strings.icon_key))
                    HorizontalDivider(
                        modifier = Modifier
                            .height(20.dp)
                            .width(20.dp)
                            .padding(start = 18.dp)
                    )
                }
            },
            trailingIcon = {
                Icon(
                    if (showPassword) Icons.Filled.Visibility else Icons.Filled.VisibilityOff,
                    contentDescription = stringResource(MR.strings.icon_visibility),
                    modifier = Modifier
                        .clickable { showPassword = !showPassword },
                )
            },
            singleLine = true,
            onValueChange = {
                if (it.contains("\n")) {
                    dispatch(RestLogin(state.login))
                }
                if (it.contains("\t")) {
                    focusManager.moveFocus(FocusDirection.Down)
                }
                dispatch(
                    SetLogin(
                        state.login.copy(
                            password = it.replace("\n", "").replace("\t", "")
                        )
                    )
                )
            },
            modifier = Modifier
                .height(55.dp)
                .widthIn(max = 280.dp)
        )
        Button(
            enabled = state.login.isValid,
            onClick = {
                if (state.login.serverUrl.isEmpty()) {
                    dispatch(SetLogin(state.login.copy(serverUrl = "http://localhost:3000")))
                }
                dispatch(RestLogin(state.login))
            },
            shape = RoundedCornerShape(8.dp),
            modifier = Modifier
                .width(280.dp)
                .height(56.dp),
        )
        {
            Box(
                modifier = Modifier
                    .fillMaxWidth()
                    .fillMaxHeight()
            ) {
                Text(
                    text = stringResource(MR.strings.action_login),
                    fontWeight = FontWeight.W300,
                    fontSize = 18.sp,
                    modifier = Modifier.align(Alignment.Center)
                )
                Icon(
                    Icons.AutoMirrored.Filled.Login,
                    contentDescription = stringResource(MR.strings.action_login),
                    modifier = Modifier.align(Alignment.CenterEnd)
                )
            }
        }
    }
}
