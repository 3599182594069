package digital.steva.formumat.helpers

enum class PlatformId {
    ANDROID,
    IOS,
    DESKTOP,
    WEB
}

expect fun platformId(): PlatformId
