package digital.steva.formumat.ui

import androidx.compose.runtime.Composable

@Composable
actual fun SignatureDialog(
    onClose: () -> Unit,
    onSave: (ByteArray?) -> Unit,
    onClear: () -> Unit
) {
}
