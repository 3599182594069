package digital.steva.dot.app

import dev.icerock.moko.resources.StringResource
import digital.steva.dot.app.middlewares.createMainMiddlware
import digital.steva.dot.app.middlewares.createOtherStoresMiddlware
import digital.steva.dot.app.middlewares.createRestMiddlware
import digital.steva.dot.app.reducers.mainReducer
import digital.steva.dot.app.rest.DocumentType
import digital.steva.dot.app.rest.Document
import digital.steva.formumat.redux.FormumatAction
import digital.steva.formumat.redux.FormumatState
import digital.steva.formumat.redux.FormumatStore
import digital.steva.formumat.redux.createFormumatState
import kotlinx.collections.immutable.PersistentList
import kotlinx.collections.immutable.persistentListOf
import kotlinx.serialization.Transient
import org.reduxkotlin.Store
import org.reduxkotlin.applyMiddleware
import org.reduxkotlin.threadsafe.createThreadSafeStore

enum class Page(val title: StringResource) {
    PAGE_LOGIN(MR.strings.page_login),
    PAGE_FORMUMAT(MR.strings.page_formumat),
    PAGE_DOCUMENTS(MR.strings.page_documents),
    PAGE_DOCUMENT(MR.strings.page_document),
    PAGE_NEW_DOCUMENT(MR.strings.page_new_document),
    PAGE_INFOS_AND_SETTINGS(MR.strings.page_infos_and_settings);
}

data class Login(
    val serverUrl: String = "",
    val username: String = "",
    val password: String = ""
) {
    @Transient
    val isValid: Boolean = serverUrl.isPresent() && username.isPresent() && password.isPresent()
}

data class AppState(
    val formumatState: FormumatState = createFormumatState(EMPTY_DATA_SCHEMA, EMPTY_UI_SCHEMA, EMPTY_VALUES),
    val currentPage: Page = Page.PAGE_LOGIN,
    val previousPage: Page = Page.PAGE_INFOS_AND_SETTINGS,
    val login: Login = Login(
        serverUrl = BuildConfig.DEFAULT_SERVER_URL,
        username = BuildConfig.DEFAULT_USERNAME,
        password = BuildConfig.DEFAULT_PASSWORD,
    ),
    val documentTypes: PersistentList<DocumentType> = persistentListOf(),
    val document: PersistentList<Document> = persistentListOf(),
    val currentDocument: Document? = null,
    val error: String? = null,
    val showSplash: Boolean = true,
    val toastMessage: StringResource? = null,
    val showProgressSpinner: Boolean = false,
    val zoomLevel: Float = 0f
)

open class AppAction

data class SetZoomLevel(val zoomLevel: Float) : AppAction()
class HideSplash : AppAction()
data class DispatchFormumat(val formumatAction: FormumatAction) : AppAction()
data class SetFormumatState(val formumatState: FormumatState) : AppAction()
data class LoadFormumatDataSchema(val schema: String) : AppAction()
data class LoadFormumatUiSchema(val schema: String) : AppAction()
data class LoadFormumatValues(val values: String) : AppAction()
class ClearFormumat : AppAction()
data class SetDocumentTypes(val documentTypes: PersistentList<DocumentType>) : AppAction()
data class SetDocuments(val documents: PersistentList<Document>) : AppAction()
data class AddDocument(val document: Document) : AppAction()
data class UpdateDocument(val document: Document) : AppAction()
data class SetCurrentDocument(val document: Document?) : AppAction()
class SetCurrentDocumentData : AppAction()
class FinishCurrentDocument : AppAction()
data class ShowPage(val page: Page) : AppAction()
class ShowPreviousPage : AppAction()
data class SetLogin(val login: Login) : AppAction()
data class ShowError(val error: String?) : AppAction()
data class RestLogin(val login: Login) : AppAction()
class RestLogout : AppAction()
class RestGetDocumentTypes : AppAction()
data class RestGetDocumentType(val id: String) : AppAction()
class RestGetDocuments : AppAction()
data class RestGetDocument(val id: String) : AppAction()
class Synchronize : AppAction()
data class ShowToast(val message: StringResource?) : AppAction()
class ShowProgressSpinner : AppAction()
class HideProgressSpinner : AppAction()

fun createAppStore(formumatStore: FormumatStore): Store<AppState> {
    return createThreadSafeStore(
        ::mainReducer,
        AppState(formumatStore.state),
        applyMiddleware(
            createOtherStoresMiddlware(formumatStore),
            createMainMiddlware(),
            createRestMiddlware()
        )
    ).apply {
        formumatStore.subscribe {
            dispatch(SetFormumatState(formumatStore.state))
        }
    }
}
