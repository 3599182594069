package digital.steva.dot.app.reducers

import digital.steva.dot.app.AddDocument
import digital.steva.dot.app.AppState
import digital.steva.dot.app.FinishCurrentDocument
import digital.steva.dot.app.HideProgressSpinner
import digital.steva.dot.app.HideSplash
import digital.steva.dot.app.SetCurrentDocument
import digital.steva.dot.app.SetCurrentDocumentData
import digital.steva.dot.app.SetDocumentTypes
import digital.steva.dot.app.SetDocuments
import digital.steva.dot.app.SetFormumatState
import digital.steva.dot.app.SetLogin
import digital.steva.dot.app.SetZoomLevel
import digital.steva.dot.app.ShowError
import digital.steva.dot.app.ShowPage
import digital.steva.dot.app.ShowPreviousPage
import digital.steva.dot.app.ShowProgressSpinner
import digital.steva.dot.app.ShowToast
import digital.steva.dot.app.UpdateDocument
import digital.steva.dot.app.rest.findById

fun mainReducer(state: AppState, action: Any) =
    when (action) {
        is SetZoomLevel -> state.copy(zoomLevel = action.zoomLevel)

        is HideSplash -> state.copy(showSplash = false)

        is SetFormumatState -> state.copy(formumatState = action.formumatState)

        is ShowPage -> state.copy(previousPage = state.currentPage, currentPage = action.page)

        is ShowPreviousPage -> state.copy(currentPage = state.previousPage)

        is ShowError -> state.copy(error = action.error)

        is ShowToast -> state.copy(toastMessage = action.message)

        is ShowProgressSpinner -> state.copy(showProgressSpinner = true)

        is HideProgressSpinner -> state.copy(showProgressSpinner = false)

        is SetLogin -> state.copy(login = action.login)

        is SetDocumentTypes -> state.copy(documentTypes = action.documentTypes)

        is SetDocuments -> state.copy(document = action.documents)

        is AddDocument -> state.copy(document = state.document.add(action.document))

        is UpdateDocument -> {
            val oldDocument = state.document.findById(action.document.id)
            if (oldDocument != null) {
                state.copy(
                    document = state.document.remove(oldDocument).add(action.document),
                    currentDocument = if (state.currentDocument == oldDocument) action.document else oldDocument,
                )
            } else state
        }

        is SetCurrentDocument -> state.copy(currentDocument = action.document)

        is SetCurrentDocumentData -> {
            if (state.currentDocument != null) {
                val newDocument = state.currentDocument.copy(
                    data = state.formumatState.data,
                    isModified = true
                )
                state.copy(
                    currentDocument = newDocument,
                    document = state.document.remove(state.currentDocument).add(newDocument)
                )

            } else state
        }

        is FinishCurrentDocument -> if (state.currentDocument != null) {
            val newDocument = state.currentDocument.copy(
                done = true,
                isModified = true
            )
            state.copy(
                currentDocument = newDocument,
                document = state.document.remove(state.currentDocument).add(newDocument)
            )

        } else state

        else -> state
    }
