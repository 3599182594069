package digital.steva.dot.app.rest

import digital.steva.dot.app.Login
import digital.steva.dot.app.rest.RestHelper.client
import io.ktor.client.request.get
import io.ktor.client.statement.HttpResponse

object SessionResource {
    suspend fun login(login: Login): HttpResponse {
        return client(login).get("${login.serverUrl}/api/login")
    }
}
